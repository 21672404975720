import React from 'react';
import Footer from './footer';
import styles from './styles.scss';

export const Layout = ({children}) => {
  return (
    <div className={styles.page}>
      <div className={styles.content} data-ui="careers-page-content">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
