import {matchPath} from 'react-router';
import {
  CAREERS_PREVIEW,
  FORM_PREVIEW,
  GDPR_PREVIEW,
  IFRAME_PREVIEW,
  JOB_PREVIEW,
  PREVIEW
} from 'candidate/shared/paths';
const location = window?.location;

export const isInDraftPreview = (path = location?.pathname) => {
  return matchPath(path, {path: PREVIEW});
};

export const isInEdit = (path, host = location?.hostname) => {
  const [subdomain] = host.split('.');
  return subdomain === 'site-editor' && !isInDraftPreview(path);
};

export const isInIframePreview = (hash = location?.hash) => {
  return [IFRAME_PREVIEW, CAREERS_PREVIEW, JOB_PREVIEW, FORM_PREVIEW, GDPR_PREVIEW].indexOf(hash) > -1;
};

export const isInPreview = (path, hash) => {
  return isInDraftPreview(path) || isInIframePreview(hash);
};
