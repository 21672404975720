import React from 'react';
import clsx from 'clsx';
import getRandomString from 'shared/ui/helpers/getRandomString';
import symbols from 'shared/ui/symbols';

import IllustratedContainer from 'shared/ui/atoms/icon/container';
import PrimaryCaptionText from 'shared/ui/atoms/caption/primary';
import Check from 'shared/ui/atoms/icon/thickCheck';
import NativeCheckboxInput from 'shared/ui/atoms/input/checkbox/native';

import NativeRadioInput from '../native';

import styles from './styles.scss';

const rolesToRenderAsCheckbox = new Set(['checkbox', 'switch']);

const processChildren = (children, radioLabelId) => {
  return React.Children.map(children, child => {
    if (!child) {
      return;
    }

    if (typeof child === 'string') {
      return <PrimaryCaptionText transparent>{child}</PrimaryCaptionText>;
    }

    if (child.props && child.props['data-radioLabel']) {
      return (
        <PrimaryCaptionText transparent id={radioLabelId}>
          {child.props.children}
        </PrimaryCaptionText>
      );
    }

    return child;
  });
};

const RadioButton = ({
  id = getRandomString(),
  defaultChecked,
  checked,
  required,
  disabled,
  standout,
  checkIcon,
  tabIndex,
  name,
  value,
  children,
  onChange,
  role,
  ...props
}) => {
  const isChecked = checked !== undefined ? checked : defaultChecked;

  const radioLabelId = `radio_label_${id}`;
  const ariaLabelledBy = props['aria-labelledby'] ? `${props['aria-labelledby']} ${radioLabelId}` : radioLabelId;
  const isCheckbox = !!role && rolesToRenderAsCheckbox.has(role);

  const NativeInput = isCheckbox ? NativeCheckboxInput : NativeRadioInput;

  return (
    <div
      data-ui="option"
      {...props}
      className={clsx(
        styles.radio,
        {
          [styles.checked]: isChecked,
          [styles.disabled]: disabled,
          [styles.standout]: standout,
          [styles['with-icon']]: checkIcon && standout
        },
        props.className
      )}
      id={`wrapper_${id}`}
      role={isCheckbox ? role : 'radio'}
      aria-checked={isChecked}
      aria-disabled={disabled}
      aria-required={required}
      aria-labelledby={ariaLabelledBy}
      tabIndex={disabled ? '-1' : tabIndex ? tabIndex : '0'}
    >
      <label>
        <NativeInput
          {...{checked, defaultChecked, disabled, name, value, onChange, required}}
          id={id}
          tabIndex="-1"
          aria-hidden
        />
        <IllustratedContainer>
          {checkIcon && standout && checked && <Check size={12} />}
          {processChildren(children, radioLabelId)}
        </IllustratedContainer>
      </label>
    </div>
  );
};

RadioButton.displayName = 'Input.RadioButton';

RadioButton[symbols.Input.Radio.Button] = true;

// eslint-disable-next-line no-unused-vars
const {readOnly: excludedReadonly, ...allowedPropTypes} = NativeRadioInput.propTypes;

RadioButton.propTypes = {
  ...allowedPropTypes
};

export default RadioButton;
