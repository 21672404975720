import React from 'react';
import {matchPath, withRouter} from 'react-router';
import {JOB_ROUTE} from 'candidate/shared/paths';
import Shortcode from './shortcode';

export class ShortcodeProvider extends React.PureComponent {
  render() {
    const {pathname: path} = this.props.location || {};
    const match = matchPath(path, {path: JOB_ROUTE});
    const shortcode = match && match.params && match.params.job;
    return (
      <Shortcode.Provider value={shortcode}>
        {this.props.children instanceof Function ? this.props.children(shortcode) : this.props.children || null}
      </Shortcode.Provider>
    );
  }
}

export default withRouter(ShortcodeProvider);
