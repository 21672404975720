import React from 'react';
import rollbar from 'candidate/rollbar';
import HttpError from './http';

export class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: props.error || undefined, networkErrorStatus: undefined};
    this.clearError = this.clearError.bind(this);
  }

  componentDidCatch(error) {
    if (rollbar && rollbar.error) {
      rollbar.error(error);
    }

    this.setState({error});
  }

  clearError() {
    this.setState({error: undefined, networkErrorStatus: undefined});
  }

  render() {
    const {error} = this.state;
    const {shouldHandleError} = this.props;
    const hasShouldHandleErrorFunction = shouldHandleError instanceof Function;

    if (error && hasShouldHandleErrorFunction && !shouldHandleError(error)) {
      throw error;
    }

    return this.props.children({clearError: this.clearError, error});
  }
}

const ErrorBoundary = ({children}) => {
  return <ErrorCatcher>{({error}) => (error ? <HttpError error={error} /> : children)}</ErrorCatcher>;
};

export const ThrowComponent = ({match: {params: {error = ''} = {}} = {}}) => {
  throw new Error(error);
};

export default ErrorBoundary;
