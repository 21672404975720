import isEmpty from 'lodash/isEmpty';

window.hj =
  window.hj ||
  function () {
    (window.hj.q = window.hj.q || []).push(arguments);
  };

export const hotjar = (hj = window.hj) => ({
  tag: (...t) => !isEmpty(t) && hj && hj('tagRecording', t)
});

export default hotjar();
