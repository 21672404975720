import React from 'react';
import Loadable from 'react-loadable';
import config from 'candidate/config';
import Loader from 'candidate/shared/loader';
import Redirect from 'candidate/shared/redirect';

const AccountMissing = Loadable({
  loader: () => config.then(props => () => <Redirect to={props && props.fallback} {...props} />),
  loading: Loader
});

export default AccountMissing;
