import React, {forwardRef} from 'react';
import clsx from 'clsx';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import symbols from 'shared/ui/symbols';
import BaseButton from '../base';
import propTypes from '../base/proptypes/flavored';
import styles from './primary.scss';

const ButtonPrimary = forwardRef(({neutral, caution, danger, muted, informational, ai, ...props}, ref) => {
  const classNames = mapPropsToStyleNames([{neutral, caution, danger, muted, informational, ai, default: 'default'}])
    .split(' ')
    .map(name => styles[name])
    .join(' ');

  return <BaseButton {...props} ref={ref} className={clsx(classNames, props.className)} />;
});

ButtonPrimary.displayName = 'Button.Primary';

ButtonPrimary[symbols.Button.Primary] = true;

ButtonPrimary.propTypes = {
  ...propTypes
};

export default ButtonPrimary;
