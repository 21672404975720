import React, {useContext} from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import compact from 'lodash/compact';
import pickBy from 'lodash/pickBy';
import {Generic, GenericCollection, JSONLD} from 'react-structured-data';
import AccountDetails from 'candidate/providers/account/details';
import Job from 'candidate/providers/job';
import {WORKPLACES} from 'candidate/shared/enums/job';
import {getJobLocations, getWorkplaceType} from 'candidate/shared/job';
import paths from 'candidate/shared/paths';
import Canonical from './canonical';

const types = Object.freeze({
  contract: 'contract',
  full: 'full-time',
  other: 'other',
  part: 'part-time',
  temporary: 'temporary'
});

const isRemote = ({remote, workplace} = {}) =>
  (!workplace && remote) || getWorkplaceType({remote, workplace}) === WORKPLACES.remote();

const isRemoteOrHybrid = ({remote, workplace} = {}) =>
  isRemote({remote, workplace}) || getWorkplaceType({remote, workplace}) === WORKPLACES.hybrid();

export const posting = ({
  benefits,
  description,
  published,
  remote,
  requirements,
  shortcode,
  title,
  type,
  workplace
} = {}) =>
  pickBy({
    datePosted: published && format(parseISO(published), 'yyyy-MM-dd'),
    description: compact([description, requirements, benefits]).join(''),
    directApply: true,
    employmentType: types[type],
    jobBenefits: benefits,
    jobLocationType: isRemoteOrHybrid({remote, workplace}) ? 'TELECOMMUTE' : undefined,
    qualifications: requirements,
    responsibilities: description,
    title,
    url: shortcode && `${window.location.origin}${paths.job(shortcode)}`
  });

export const applicantLocationReq = ({country} = {}) =>
  pickBy({
    name: country
  });

const organization = ({logo, name, subdomain, url} = {}) =>
  pickBy({
    identifier: subdomain,
    logo,
    name,
    sameAs: url
  });

const location = ({city, country, region} = {}) =>
  pickBy({
    addressCountry: country,
    addressLocality: city,
    addressRegion: region
  });

const StructuredData = () => {
  const isApplicationTabRoute = window.location.pathname.includes('/apply');
  const {accountDetails: account} = useContext(AccountDetails) || {};
  const {job} = useContext(Job) || {};
  const jobLocations = getJobLocations(job);
  const locationsWithCountry = jobLocations.filter(jobLocation => Boolean(jobLocation.country));

  if (isApplicationTabRoute || !account || !job || !job.title || !job.description) {
    return null;
  }

  return (
    <>
      <Canonical />
      <JSONLD>
        <Generic jsonldtype="JobPosting" schema={posting(job)} type="review">
          {/**
           * `applicantLocationRequirements`
           * The geographic location(s) in which employees may be located for
           * to be eligible for the Work from home job. The job description
           * must clearly state that the applicant may be located in certain
           * geographical location(s). This property is required if applicants
           * may be located in one or more geographic locations and the job is
           * 100% remote.
           */}
          {isRemoteOrHybrid(job) && locationsWithCountry.length > 0 && (
            <GenericCollection type="applicantLocationRequirements">
              {locationsWithCountry.map((jobLocation, index) => (
                <Generic jsonldtype="Country" key={index} schema={applicantLocationReq(jobLocation)} />
              ))}
            </GenericCollection>
          )}
          <Generic jsonldtype="Organization" schema={organization(account)} type="hiringOrganization" />
          {isRemote(job)
            ? null
            : jobLocations.length > 0 && (
                <GenericCollection type="jobLocation">
                  {jobLocations.map((jobLocation, index) => (
                    <Generic jsonldtype="Place" key={index}>
                      <Generic jsonldtype="PostalAddress" schema={location(jobLocation)} type="address" />
                    </Generic>
                  ))}
                </GenericCollection>
              )}
          <Generic jsonldtype="PropertyValue" schema={{name: account.name, value: job.shortcode}} type="identifier" />
        </Generic>
      </JSONLD>
    </>
  );
};

export default StructuredData;
