import React from 'react';
import clsx from "clsx";
import Radio from 'shared/ui/atoms/input/radio';
import Control from 'shared/ui/molecules/control/text';
import symbols from 'shared/ui/symbols';
import styles from './styles.scss';

const RadioControl = ({label, helper, error, optional, warning, tooltip, required, labelWrap, className, ...props}) => (
  <Control
    className={clsx(styles["control-radio"], className)}
    label={label}
    helper={helper}
    error={error}
    optional={optional}
    tooltip={tooltip}
    warning={warning}
    required={required}
    labelWrap={labelWrap}>
    <Radio {...props} required={required} />
  </Control>
);

RadioControl[symbols.Control.Radio] = true;
RadioControl.displayName = 'Control.Radio';

RadioControl.propTypes = {
  ...Control.propTypes,
  ...Radio.propTypes
};

export default RadioControl;
