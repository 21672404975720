import React from 'react';
import qs from 'query-string';
import Shortcode from 'candidate/providers/shortcode';
import SourcedCandidate from './sourcedCandidate';

const SourcedCandidateProvider = ({children, location = window.location}) => {
  const shortcode = React.useContext(Shortcode);
  const [cid, setCid] = React.useState(qs.parse(location.search).cid);

  React.useEffect(() => setCid(qs.parse(location.search).cid), [shortcode]);

  return (
    <SourcedCandidate.Provider value={cid}>
      {children instanceof Function ? children(cid) : children || null}
    </SourcedCandidate.Provider>
  );
};

export default SourcedCandidateProvider;
