export const status = {
  TIMEOUT: 'timeout',
  SUCCESS: 'success',
  SKIPPED: 'skipped'
};

/**
 * Load a JS script programmatically.
 *
 * The <script> element get inserted as the last child of <body> by default but can be customized
 * via `insertAfter` or `insertBefore` options.
 *
 * @param {String} url - the URL of the script
 * @param {Object} [options] - options
 * @param {Boolean} [options.async=true] - whether to execute the script asynchronously, as soon as it is available
 * @param {Boolean} [options.defer=false] - whether to execute the script after the page has finished parsing
 * @param {Number} [options.timeout=5000] - the timeout in ms after which the returned promise is rejected
 *  Keep in mind, that this doesn't guarantee that the script won't be parsed or executed
 *  eventually. There is no way to abort it. It only means, that we shouldn't care if it does after
 *  the timeout.
 * @param {Object} [options.attrs] - extra attributes to set on the script tag
 * @param {Function} [options.skipIf] - a convenience option. Return a truthy value to skip loading the script altogether.
 * @param {Node} [options.insertAfter] - if given, the <script> element will be inserted after `insertAfter`
 * @param {Node} [options.insertBefore] - if given, the <script> element will be inserted before `insertBefore`
 * @returns {Promise} - resolves `onload` and rejects `onerror`
 */
export default function loadScript(
  url,
  {async = true, defer = false, insertAfter: aref, insertBefore: bref, timeout = 5000, attrs, skipIf} = {}
) {
  return new Promise((resolve, reject) => {
    // Skip loading for specs
    if (process.env.NODE_ENV === 'test') {
      resolve(status.SKIPPED);
      return;
    }

    if (typeof skipIf === 'function' && skipIf()) {
      resolve(status.SKIPPED);
      return;
    }

    const rejectWithTimeout = setTimeout(() => reject(status.TIMEOUT), timeout);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = async;
    script.defer = defer;

    if (attrs) {
      for (const attr in attrs) {
        script.setAttribute(attr, attrs[attr]);
      }
    }

    script.onerror = err => {
      reject(new URIError(`loadScript: the script ${err.target.src} is not accessible.`));
    };

    script.onload = () => {
      clearTimeout(rejectWithTimeout);
      resolve(status.SUCCESS);
    };

    if (aref) {
      aref.parentNode.insertBefore(script, aref.nextSibling);
      return;
    }

    if (bref) {
      bref.parentNode.insertBefore(script, bref);
      return;
    }

    document.body.appendChild(script);
  });
}
