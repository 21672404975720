import React from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../base';

const Radio = ({name, value, disabled, checked, onChange, ...props}) => (
  <BaseInput
    {...props}
    type="radio"
    name={name}
    onChange={onChange}
    checked={checked}
    value={value}
    disabled={disabled}
  />
);

Radio.propTypes = {
  id: PropTypes.string,
  /** Render the radio input as checked */
  checked: PropTypes.bool,
  /** Render the radio input as disabled */
  disabled: PropTypes.bool,
  /** The name of the radio. Radios are grouped together based on name*/
  name: PropTypes.string,
  /** Triggered when an input changes value */
  onChange: PropTypes.func,
  /** Marks this input as required as part of a form */
  required: PropTypes.bool,
  /** The value the radio button has */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Radio.displayName = 'Input.Radio';

export default Radio;
