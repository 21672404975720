import React, {useState, useCallback} from 'react';
import clsx from "clsx";
import NativeRadio from '../native';
import RadioFilledIcon from 'shared/ui/atoms/icon/radioFilled';
import RadioFilledEmpty from 'shared/ui/atoms/icon/radioEmpty';
import symbols from 'shared/ui/symbols';
import getRandomString from 'shared/ui/helpers/getRandomString';
import styles from './styles.scss';

const Radio = ({
  children,
  checkIcon,
  checked,
  defaultChecked,
  required,
  disabled,
  readOnly,
  name,
  value,
  onChange,
  id = getRandomString(),
  tabIndex,
  RadioEmpty = RadioFilledEmpty,
  RadioFilled = RadioFilledIcon,
  ...props
}) => {
  const radioProps = {
    id: `svg_${id}`
  };

  const [isChecked, setIsChecked] = useState(checked !== undefined ? checked : defaultChecked);
  const isControlled = checked !== undefined && onChange !== undefined;

  const radioToShow = (isControlled ? checked : isChecked) ? (
    <RadioFilled
      {...radioProps}
      className={clsx(styles["radio-filled"], radioProps.className)} />
  ) : (
    <RadioEmpty
      {...radioProps}
      className={clsx(styles["radio-empty"], radioProps.className)} />
  );

  const handleChange = useCallback(
    e => {
      if (!isControlled) {
        setIsChecked(e.target.checked);
      }

      if (onChange) {
        onChange(e);
      }
    },
    [onChange, isControlled]
  );

  return (
    (<div
      {...props}
      className={clsx(styles.radio, props.className)}
      id={`wrapper_${id}`}
      role="radio"
      aria-checked={isControlled ? checked : isChecked}
      aria-disabled={disabled}
      aria-readonly={readOnly}
      aria-required={required}
      tabIndex={disabled ? '-1' : tabIndex ? tabIndex : '0'}
    >
      <NativeRadio
        {...{defaultChecked, disabled, name, value, required}}
        checked={isControlled ? checked : undefined}
        onChange={handleChange}
        id={id}
        tabIndex="-1"
        aria-hidden
      />
      {radioToShow}
    </div>)
  );
};

Radio.displayName = 'Input.Radio';

Radio[symbols.Input.Radio.Naked] = true;

Radio.propTypes = {
  ...NativeRadio.propTypes
};

export default Radio;
