import {useContext, useEffect} from 'react';
import {matchPath} from 'react-router';
import Job from 'candidate/providers/job';
import paths, {JOB_ROUTE} from 'candidate/shared/paths';

/**
 * When viewing a job or application form, adds a <link rel="canonical", always
 * pointing to the original job shortcode.
 *
 * This is solely for SEO purposes.
 */
const Canonical = ({location = window.location}) => {
  const {job} = useContext(Job);
  const shortcode = job && job.shortcode;

  useEffect(() => {
    let metatag = window.document.head.querySelector('link[rel="canonical"]');
    if (!shortcode || !matchPath(location.pathname, {path: JOB_ROUTE})) {
      if (metatag && metatag.remove) {
        metatag.remove();
      }
      return;
    }

    if (!metatag) {
      metatag = document.createElement('link');
      window.document.head.appendChild(metatag);
    }

    metatag.setAttribute('rel', 'canonical');
    metatag.setAttribute('href', `${location.origin}${paths.job(shortcode)}`);
  });
  return null;
};

export default Canonical;
