/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import config from 'candidate/config';
import featurePolicy from 'candidate/featuresPolicy';
import Recaptcha from './recaptcha';

const RecaptchaProvider = ({children}) => {
  const execute = action => {
    const fp = featurePolicy();
    if (Object.keys(fp.features()).includes('recaptcha') && !fp.allows('recaptcha')) {
      return Promise.resolve('disabled');
    }
    return config.then(
      ({recaptcha}) =>
        window.grecaptcha &&
        window.grecaptcha.execute &&
        window.grecaptcha.execute(recaptcha && recaptcha.clientKey, {action})
    );
  };
  return (
    <Recaptcha.Provider value={execute}>
      {children instanceof Function ? children(execute) : children || null}
    </Recaptcha.Provider>
  );
};

export default RecaptchaProvider;
