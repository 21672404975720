import React from 'react';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';

const Icon = props => (
  <BaseSvg {...props}>
    <Graph fill {...props}>
      <path
        transform="translate(0, 1.5)"
        d="M13.7357468,0.387338776 L5.3278608,8.79522476 L2.26425321,5.73161717 C1.74000771,5.22528393 0.90669323,5.2325252 0.391326028,5.7478924 C-0.124041173,6.26325961 -0.131282451,7.09657408 0.375050797,7.62081959 L4.38325959,11.6290284 C4.90499457,12.1506058 5.75072703,12.1506058 6.27246201,11.6290284 L15.6249492,2.27654119 C16.1312825,1.75229569 16.1240412,0.918981209 15.608674,0.403614007 C15.0933068,-0.111753194 14.2599923,-0.118994472 13.7357468,0.387338776 Z"
      />
    </Graph>
  </BaseSvg>
);

Icon.displayName = 'Icon.ThickCheck';

setSymbol(Icon);

export default Icon;
