import React from 'react';
import PropTypes from 'prop-types';
import BaseInput from '../../base';

const Checkbox = ({required, name, disabled, checked, onChange, ...props}) => (
  <BaseInput
    {...props}
    type="checkbox"
    required={required}
    name={name}
    onChange={onChange}
    checked={checked}
    disabled={disabled}
  />
);

Checkbox.propTypes = {
  /** The name of the checkbox.*/
  name: PropTypes.string,
  /** If true, renders the checkbox as disabled */
  disabled: PropTypes.bool,
  /** If true, renders the checkbox as checked */
  checked: PropTypes.bool,
  /** Triggered when an input changes value */
  onChange: PropTypes.func,
  /** Tags this input as required per HTML spec. Usually used when part of a form */
  required: PropTypes.bool
};

Checkbox.displayName = 'Input.Checkbox';

export default Checkbox;
