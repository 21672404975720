/* eslint-disable react/jsx-no-constructed-context-values */
import React, {Component} from 'react';
import {matchPath, withRouter} from 'react-router';
import api from 'candidate/api';
import Account from 'candidate/providers/account';
import {JOB_ROUTE} from 'candidate/shared/paths';
import Job from './job';

export class JobProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {error: props.error, job: undefined};
    this.updateJob = this.updateJob.bind(this);
    this.fetchJob = this.fetchJob.bind(this);
  }

  componentDidMount() {
    this.fetchJob();
  }

  componentDidUpdate(previousProps) {
    const {pathname: path} = this.props.location || {};
    const {pathname: previousPath} = previousProps.location || {};
    if (previousPath !== path) {
      this.fetchJob();
    }
  }

  fetchJob() {
    const {pathname: path} = this.props.location || {};
    const account = this.props.account;
    if (!account || !path) {
      return;
    }

    const match = matchPath(path, {path: JOB_ROUTE});
    const shortcode = match && match.params && match.params.job;
    const current = this.state.job || {};
    if (current.shortcode !== shortcode && current.posting !== shortcode) {
      this.setState(
        {error: undefined, job: undefined},
        () =>
          shortcode &&
          api
            .account(account)
            .job(shortcode)
            // Appends posting's shortcode as well as shortcode.
            // was one of a posting, since posting != job.shortcode.
            .then(job => this.updateJob({...job, posting: shortcode}))
            .catch(error => this.setState({error, job: undefined}))
      );
    }
  }

  updateJob(job) {
    this.setState({error: undefined, job});
  }

  render() {
    const {error, job} = this.state;
    if (error) {
      throw error;
    }

    return (
      <Job.Provider value={{job, updateJob: this.updateJob}}>
        {this.props.children instanceof Function
          ? this.props.children({job, updateJob: this.updateJob})
          : this.props.children || null}
      </Job.Provider>
    );
  }
}

export const Container = props => (
  <Account.Consumer>{account => <JobProvider account={account} {...props} />}</Account.Consumer>
);

export default withRouter(Container);
