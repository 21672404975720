import React from 'react';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';

const Icon = props => (
  <BaseSvg {...props}>
    <Graph fill {...props}>
      <path d="M8 4C5.76 4 4 5.76 4 8s1.76 4 4 4 4-1.76 4-4-1.76-4-4-4zm0-4C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14.4c-3.52 0-6.4-2.88-6.4-6.4 0-3.52 2.88-6.4 6.4-6.4 3.52 0 6.4 2.88 6.4 6.4 0 3.52-2.88 6.4-6.4 6.4z" />
    </Graph>
  </BaseSvg>
);

Icon.displayName = 'Icon.RadioFilled';

setSymbol(Icon);

export default Icon;
