import React, {forwardRef, memo, useCallback, useState, useRef} from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import RemoveIcon from 'shared/ui/atoms/icon/remove';
import ActionableIcon from 'shared/ui/atoms/icon/actionable';
import createPseudoEvent from 'shared/ui/helpers/createPseudoEvent';
import {useEvergreenTranslations} from 'shared/ui/providers/translations';
import styles from './styles.scss';
const NOOP = () => {};

const withClearable = (Input, defaultProps = {}) =>
  memo(
    forwardRef(({clearable = true, ...props}, ref) => {
      const isControlled = 'value' in props && 'onChange' in props;
      const {disabled, readOnly, texts: _texts, onChange = NOOP, onLoad = NOOP, children} = props;
      const [value, setValue] = useState(props.value || props.defaultValue);
      const inputRef = useRef(null);
      const isVisible = clearable && !!(isControlled ? props.value : value) && !disabled && !readOnly;

      const texts = useEvergreenTranslations('input.text', _texts);

      const handleClear = useCallback(
        event => {
          event.cancelBubble = true;
          event.preventDefault();

          if (!isControlled) {
            setValue('');
            inputRef.current.value = '';
          }

          const pseudoEvent = createPseudoEvent('', props.name);
          onChange(pseudoEvent);

          if (inputRef.current && typeof inputRef.current.focus === 'function') {
            inputRef.current.focus();
          }
        },
        [onChange, isControlled]
      );

      const callbackRef = useCallback(
        el => {
          if (!el || inputRef.current === el) {
            return;
          }
          inputRef.current = el;
          if (typeof onLoad === 'function') {
            onLoad(el);
          }
        },
        [onLoad]
      );

      const handleChange = useCallback(
        event => {
          onChange(event);
          if (!isControlled) {
            setValue(event.target.value);
          }
          inputRef.current = event.target;
        },
        [onChange, isControlled]
      );

      return (
        (<Input
            {...defaultProps}
            {...props}
            ref={ref}
            value={isControlled ? props.value : value}
            onChange={handleChange}
            onLoad={callbackRef}
          >
          {children}
          {clearable ? (
            <ActionableIcon
              className={clsx({
                [styles["clear-icon-button"]]: true,
                [styles.visible]: isVisible,
                [styles.clickable]: isVisible
              })}
              size="12"
              tabIndex={isVisible ? '0' : ''}
              data-visible={isVisible}
              aria-hidden={!isVisible}
              data-clear
              aria-label={`${texts.clearIconLabel} ${isControlled ? props.value : value}`}
              onClick={handleClear}
              handleEnterPressed={handleClear}
              handleSpacePressed={handleClear}
            >
              <RemoveIcon className={styles["clear-icon"]} size="14" transparent fill right />
            </ActionableIcon>
          ) : null}
        </Input>)
      );
    })
  );

withClearable.PropTypes = {
  clearable: PropTypes.bool
};

export default withClearable;
