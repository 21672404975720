/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import Search from './search';

export default class SearchProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        department: new Set(),
        location: new Set(),
        remote: new Set(),
        workplace: new Set(),
        worktype: new Set()
      },
      pending: false,
      query: ''
    };
    this.updateQuery = debounce(this.updateQuery.bind(this), 500, {maxWait: 6000});
    this._updateQuery = this._updateQuery.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setFilters = this.setFilters.bind(this);
  }

  updateQuery(query) {
    this.setState({pending: false, query});
  }

  _updateQuery(query) {
    this.setState({pending: true}, () => this.updateQuery(query));
  }

  updateFilters(filters) {
    // Always create a shallow copy of filters, in order to trigger a repaint of the React.PureComponent.
    this.setState({filters: {...filters}});
  }

  setFilter(type, data) {
    this.updateFilters({
      ...this.state.filters,
      [type]: new Set(data)
    });
  }

  setFilters({department = [], location = [], remote = [], workplace = [], worktype = []}) {
    this.updateFilters({
      department: new Set(department),
      location: new Set(location),
      remote: new Set(remote),
      workplace: new Set(workplace),
      worktype: new Set(worktype)
    });
  }

  addFilter(filter) {
    if (filter) {
      const filters = this.state.filters;
      Object.keys(filter).forEach(k =>
        Array.isArray(filter[k]) ? filter[k].forEach(f => filters[k].add(f)) : filters[k].add(filter[k])
      );
      this.updateFilters(filters);
    }
  }

  removeFilter(filter) {
    if (filter) {
      const filters = this.state.filters;
      Object.keys(filter).forEach(k => {
        return isPlainObject(filter[k])
          ? filters[k].forEach(record => (isEqual(record, filter[k]) ? filters[k].delete(record) : record))
          : filters[k].delete(filter[k]);
      });
      this.updateFilters(filters);
    }
  }

  clearAll() {
    this.setState({
      filters: {
        department: new Set(),
        location: new Set(),
        remote: new Set(),
        workplace: new Set(),
        worktype: new Set()
      },
      pending: false,
      query: ''
    });
  }

  render() {
    const {
      filters: {department, location, remote, workplace, worktype},
      pending,
      query
    } = this.state;

    return (
      <Search.Provider
        value={{
          addFilter: this.addFilter,
          clearAll: this.clearAll,
          removeFilter: this.removeFilter,
          search: {
            filters: {
              department: [...department],
              location: [...location],
              remote: [...remote],
              workplace: [...workplace],
              worktype: [...worktype]
            },
            pending,
            query
          },
          setFilter: this.setFilter,
          setFilters: this.setFilters,
          updateQuery: this._updateQuery
        }}
      >
        {this.props.children}
      </Search.Provider>
    );
  }
}
