import React from 'react';
import clsx from "clsx";
import TextBase from '../../text/base';
import styles from './styles.scss';

const Primary = props => <TextBase {...props} className={clsx(styles.caption1, props.className)} defaultFlavor="secondary" skeletonHeight="20px" />;

Primary.displayName = 'Caption.Primary';

export default Primary;
