import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import Group from 'shared/ui/molecules/group';
import KeyboardHandler from 'shared/ui/behaviors/keyboardHandler';
import createPseudoEvent from 'shared/ui/helpers/createPseudoEvent';
import propsFilter from 'shared/ui/helpers/propsFilter';
import styles from './styles.scss';

const renderChildrenRadios = (children, value, required, disabled, name, standout, checkIcon, onChange) => {
  return React.Children.map(children, (child, idx) => {
    if (!child) {
      return null;
    }

    const isChecked = value === undefined ? child.props.checked : child.props.value === value;

    // TEMP FIX
    // Remove `required` attribute from HTML input, when input has value.
    return symbols.Input.Radio.includes(child.type) || child.type[symbols.Control.Radio]
      ? React.cloneElement(child, {
          name,
          required: required && !value,
          onChange: onChange || child.props.onChange,
          checked: isChecked,
          tabIndex: isChecked ? '0' : (!value && idx) === 0 ? '0' : '-1',
          disabled: disabled || child.props.disabled,
          standout: child.props.standout || standout,
          checkIcon: child.props.checkIcon || checkIcon
        })
      : child;
  });
};

/**
 * RadioGroup. Renders the nested `Control.Radio` elements in a group assigning them
 * the same set of properties as the group itself.
 * @example
 * ```js
 <RadioGroupControl value='valueB' onChange={handleOnChange} required stacked>
  <RadioControl value="valueA">Option A<RadioControl/>
  <RadioControl value="valueB">Option B<RadioControl/>
 </RadioGroupControl>
 * ```
 */
class RadioGroup extends React.Component {
  state = {
    value: undefined,
    isControlled: false
  };

  static getDerivedStateFromProps(props, prevState) {
    if ('value' in props) {
      return {value: props.value, isControlled: true};
    }

    if ('defaultValue' in props && prevState.value === undefined) {
      return {value: props.defaultValue};
    }

    return null;
  }

  values = [];

  selectOnArrowPress = (e, {direction}) => {
    e.preventDefault();

    const {DOWN, RIGHT} = KeyboardHandler.constants.DIRECTIONS;
    const moveIndex = direction === DOWN || direction === RIGHT ? 1 : -1;
    const currentIndex = this.values.indexOf(this.state.value);
    const lastIndex = this.values.length - 1;
    let nextSelectedIndex = currentIndex + moveIndex;

    if (nextSelectedIndex < 0) {
      nextSelectedIndex = lastIndex;
    }

    if (currentIndex === -1 && moveIndex === 1) {
      nextSelectedIndex = moveIndex;
    }

    if (nextSelectedIndex > lastIndex) {
      nextSelectedIndex = 0;
    }

    const newSelectedValue = this.values[nextSelectedIndex];
    const currentSelectedNode = e.currentTarget.querySelector(`[value*="${newSelectedValue}"]`);

    this.handleSelection(newSelectedValue, currentSelectedNode);
  };

  getAncestorRadioElement(currentElement, maxIterations = 5) {
    let candidateElement = currentElement.parentNode;
    let iteration = 0;
    while (candidateElement && candidateElement.getAttribute('role') !== 'radio' && iteration <= maxIterations) {
      candidateElement = candidateElement.parentNode;
      iteration = iteration + 1;
    }
    return iteration <= maxIterations ? candidateElement : undefined;
  }

  handleSelection(newSelectedValue, currentSelectedNode) {
    const {onChange} = this.props;
    const prevSelected = this.state.value;
    const event = createPseudoEvent(newSelectedValue, this.props.name);
    const triggerChange = () => onChange(event, {previous: prevSelected, current: newSelectedValue});

    if (!this.state.isControlled) {
      this.setState({value: newSelectedValue});
    }

    const radioElement = this.getAncestorRadioElement(currentSelectedNode);
    if (radioElement) {
      radioElement.addEventListener('focus', triggerChange);
      radioElement.blur();
      radioElement.focus();
      radioElement.removeEventListener('focus', triggerChange);
    }
  }

  selectOnClick = e => {
    const {onChange} = this.props;
    const prevSelected = this.state.value;
    if (!this.state.isControlled) {
      this.setState({value: e.currentTarget.value});
    }
    onChange(e, {previous: prevSelected, current: e.currentTarget.value});
  };

  handleSpacePress = event => {
    event.preventDefault();

    const inputNode = event.target && event.target.querySelector('input');
    const newSelectedValue = inputNode && inputNode.value;

    if (this.state.value !== newSelectedValue) {
      this.handleSelection(newSelectedValue, inputNode);
    }
  };

  getContainerTypes() {
    const firstRadioChild = React.Children.toArray(this.props.children).find(child => {
      return symbols.Input.Radio.includes(child.type);
    });

    if (!firstRadioChild || !firstRadioChild.type) {
      return {};
    }

    const radioButtonContainer = Boolean(firstRadioChild.type[symbols.Input.Radio.Button]);
    const richRadioContainer = Boolean(firstRadioChild.type[symbols.Input.Radio.Rich]);

    return {radioButtonContainer, richRadioContainer};
  }

  render() {
    const {
      children,
      required,
      disabled,
      stacked,
      tight,
      responsive,
      name,
      standout,
      checkIcon,
      separated,
      as = 'fieldset'
    } = this.props;

    const {radioButtonContainer, richRadioContainer} = this.getContainerTypes();

    this.values = React.Children.map(children, child => child && child.props && child.props.value);
    const allowedProps = propsFilter(this.props).ariaAttributes().dataAttributes().styles().getFiltered();

    return (
      (<KeyboardHandler handleArrowsPressed={this.selectOnArrowPress} handleSpacePressed={this.handleSpacePress}>
        <Group
          as={as}
          stacked={stacked}
          rounded={radioButtonContainer}
          role="radiogroup"
          {...allowedProps}
          className={clsx({
            [styles["radio-group"]]: true,
            [styles.stacked]: stacked,
            [styles.tight]: tight,
            [styles.responsive]: responsive,
            [styles.standout]: standout,
            [styles.separated]: separated,
            [styles["rich-radios"]]: richRadioContainer,
            [styles["radio-buttons"]]: radioButtonContainer
          }, allowedProps.className)}
        >
          {renderChildrenRadios(
            children,
            this.state.value,
            required,
            disabled,
            name,
            standout,
            checkIcon,
            this.selectOnClick
          )}
        </Group>
      </KeyboardHandler>)
    );
  }
}

RadioGroup.defaultProps = {
  onChange: () => {}
};

RadioGroup.displayName = 'Control.RadioGroup';

RadioGroup[symbols.Group.Radio] = true;

RadioGroup.propTypes = {
  /** The name of the radio children. Radios are grouped together based on name */
  name: PropTypes.string.isRequired,
  /**
   * In case you need controlled radio group, the value of the input should be rendered as selected.
   * to render each subsequent selected element should listen on `onChange` and pass the new value
   * as prop again to the component
   */
  value: PropTypes.string,
  /** Uncontrolled radio group. The value of the input should be rendered as selected */
  defaultValue: PropTypes.string,
  /** When passed as prop stacks the inputs vertically */
  stacked: PropTypes.bool,
  /** Marks this input as required as part of a form */
  required: PropTypes.bool,
  /** Triggered when an input changes value */
  onChange: PropTypes.func,
  /** Element to render the radio group. Defaults to `fieldset` */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

export default RadioGroup;
