import React from 'react';
import styles from './styles.scss';

const Loader = () => (
  <div className={styles.loader}>
    <div className={styles.inner}>
      <div className={styles.first} />
      <div className={styles.second} />
      <div className={styles.third} />
      <div className={styles.fourth} />
    </div>
  </div>
);

export default Loader;
