/* eslint-disable react/jsx-no-constructed-context-values */
import React, {useCallback, useEffect, useState} from 'react';
import Alert from 'shared/ui/organisms/alert';
import Flash from './flash';
import styles from './styles.scss';

const DANGER = 'danger';
const INFORMATIONAL = 'informational';

export const _timeout = {last: undefined};

const FlashContainer = ({
  _clearTimeout = clearTimeout,
  _setTimeout = setTimeout,
  children,
  className = styles.flash,
  flavor,
  text
}) => {
  const [options, setOptions] = useState({
    flavor: flavor || INFORMATIONAL,
    message: text || '',
    timeout: undefined
  });

  const flashError = useCallback(
    (message, timeout) => {
      setOptions({
        flavor: DANGER,
        message,
        timeout
      });
    },
    [setOptions]
  );

  const close = useCallback(() => setOptions({message: ''}), [setOptions]);

  const flashInfo = useCallback(
    (message, timeout) => {
      setOptions({
        flavor: INFORMATIONAL,
        message,
        timeout
      });
    },
    [setOptions]
  );

  useEffect(() => {
    if (_timeout.last) {
      _clearTimeout(_timeout.last);
    }
    delete _timeout.last;
    if (options.timeout) {
      _timeout.last = _setTimeout(close, options.timeout);
    }
  }, [options, close]);

  const {flavor: optionsFlavor, message} = options || {};

  return (
    <Flash.Provider value={{flashError, flashInfo}}>
      {message && (
        <div className={className} data-ui="flash-container">
          <Alert.Flash
            danger={optionsFlavor === DANGER}
            informational={optionsFlavor === INFORMATIONAL}
            message={message}
            onClose={close}
          />
        </div>
      )}
      {children}
    </Flash.Provider>
  );
};

export default FlashContainer;
