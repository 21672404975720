import React, {useContext, useEffect, useState} from 'react';
import api from 'candidate/api';
import Account from 'candidate/providers/account';
import Loader from 'candidate/shared/loader';
import JobsCount from './count';

export const JobsCountProvider = ({_count, _error, account, children}) => {
  const [error, setError] = useState(_error);
  const [count, setCount] = useState(_count);

  useEffect(() => {
    if (!account) {
      return;
    }
    api.account(account).count().then(setCount).catch(setError);
  }, [account]);

  if (error) {
    // Do not break page rendering, if the api call failed, instead render the
    // children with the default Context.
    return children || null;
  }

  // We block rendering children until we receive a server response,
  // Unfortunately this makes the UI block for longer.
  if (!count && account) {
    return <Loader />;
  }

  return <JobsCount.Provider value={count}>{children}</JobsCount.Provider>;
};

export default props => <JobsCountProvider account={useContext(Account)} {...props} />;
