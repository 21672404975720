import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import getRandomString from 'shared/ui/helpers/getRandomString';
import symbols from 'shared/ui/symbols';
import {useEvergreenTranslations} from 'shared/ui/providers/translations';

import IllustratedInput from 'shared/ui/atoms/input/text/illustrated';
import TextBody from 'shared/ui/atoms/text/body';
import TextSecondary from 'shared/ui/atoms/text/secondary';
import Tooltip from 'shared/ui/organisms/tooltip';
import IconQuestionCircle from 'shared/ui/atoms/icon/questionCircle';
import {SkeletonInput} from 'shared/ui/atoms/skeleton';

import styles from './styles.scss';

const preventDefault = e => e.preventDefault();

const getCounterText = (count, max) => {
  return `${count || 0}${max ? `/${max}` : ''}`;
};

const getOptionalText = text => {
  return ` (${text})`;
};

const areChildrenIconsOnly = children => {
  return children && React.Children.toArray(children).every(child => child.type[symbols.Icon]);
};

const TextField = React.forwardRef(
  (
    {
      id = getRandomString(),
      texts: _texts,
      labelWrap = true,
      as: InputKind,
      label,
      helper,
      error,
      warning,
      counter,
      required,
      optional,
      tooltip,
      skeleton,
      skeletonProps,
      wrapperCallbackRef,
      className,
      style,
      children,
      ...props
    },
    ref
  ) => {
    const texts = useEvergreenTranslations('control', _texts);

    const helperId = `${id}_helper`;
    const errorId = `${id}_error`;
    const warningId = `${id}_warning`;
    const labelId = `${id}_label`;
    const counterId = `${id}_counter`;

    const hasLabel = Boolean(label);
    const hasTooltip = Boolean(tooltip);
    const hasHelper = Boolean(helper);
    const hasError = Boolean(error);
    const hasWarning = Boolean(warning);
    const hasCounter = Boolean(counter);
    const hasItems = hasHelper || hasError || hasWarning || hasCounter;
    const hasOnlyIconChildren = areChildrenIconsOnly(children);

    const describedBy = hasError ? errorId : hasWarning ? warningId : hasHelper ? helperId : undefined;
    const ariaProps = {
      ...(hasLabel ? {'aria-labelledby': labelId} : {}),
      ...(describedBy ? {'aria-describedby': describedBy} : {})
    };

    if (skeleton) {
      return <SkeletonInput heading={label} data-ui="skeleton-control" {...skeletonProps} />;
    }

    const Wrapper = labelWrap ? 'label' : 'div';

    return (
      <div className={clsx(styles.field, className)} style={style} ref={wrapperCallbackRef}>
        <Wrapper className={styles['input-group']}>
          {hasLabel && (
            <span className={styles.label}>
              {required && (
                <TextBody strong danger className={styles.required}>
                  *
                </TextBody>
              )}

              <span>
                <TextBody id={labelId} strong>
                  {label}
                </TextBody>
                {optional && (
                  <TextSecondary className={styles.optional}>{getOptionalText(texts.optional)}</TextSecondary>
                )}
                {hasTooltip && (
                  <span className={styles.tooltip}>
                    {React.isValidElement(tooltip) ? (
                      React.cloneElement(tooltip, {onClick: tooltip.props.onClick || preventDefault})
                    ) : (
                      <Tooltip placement="right" title={tooltip} onClick={preventDefault}>
                        <IconQuestionCircle transparent />
                      </Tooltip>
                    )}
                  </span>
                )}
              </span>
            </span>
          )}

          {children && !hasOnlyIconChildren ? (
            !labelWrap && React.Children.count(children) === 1 && React.isValidElement(children) ? (
              React.cloneElement(children, {
                ...ariaProps,
                id: `input_${id}`
              })
            ) : (
              children
            )
          ) : (
            <IllustratedInput
              ref={ref}
              id={id}
              as={InputKind}
              {...props}
              {...ariaProps}
              error={hasError}
              warning={hasWarning}
              required={required}
              children={children}
            />
          )}
        </Wrapper>
        {hasItems && (
          <div className={styles['field-items']}>
            <div className={styles.left}>
              {hasError && (
                <TextSecondary danger className={clsx(styles.helper, styles.error)} id={errorId}>
                  {error}
                </TextSecondary>
              )}
              {hasWarning && (
                <TextSecondary danger className={clsx(styles.helper, styles.warning)} id={warningId}>
                  {warning}
                </TextSecondary>
              )}
              {hasHelper && (
                <TextSecondary className={styles.helper} id={helperId}>
                  {helper}
                </TextSecondary>
              )}
            </div>

            {hasCounter && (
              <TextSecondary className={styles.counter} data-ui="counter" id={counterId}>
                {getCounterText(props.value?.length, props.maxLength)}
              </TextSecondary>
            )}
          </div>
        )}
      </div>
    );
  }
);

TextField.propTypes = {
  /** Wraps the input in a label. */
  labelWrap: PropTypes.bool,
  /** The text which will be rendered as the label of the field. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** The name attribute of the input. */
  name: PropTypes.string,
  /** The value of the input. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The placeholder attribute of the input. */
  placeholder: PropTypes.string,
  /** The helper text that describes this field. */
  helper: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** The error message that should be rendered. If exists, makes also input styled as error. */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  /** The warning message that should be rendered. If exists, makes also input styled as warning. */
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
  /** Controls whether to display a character counter. */
  counter: PropTypes.bool,
  /** Renders a required field. Changes the label of this field and makes the input has required attribute. */
  required: PropTypes.bool,
  /** Renders an optional field. Changes the label of this field. */
  optional: PropTypes.bool,
  /** Adds a tooltip icon and displays a message on user focus. */
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Renders a readonly input. User is unable to change it. */
  readOnly: PropTypes.bool,
  /** Renders a disabled input. User is unable to click it. */
  disabled: PropTypes.bool,
  /** The handler which will be called when the input changes value. */
  onChange: PropTypes.func,
  /** All the texts might be used inside the component.*/
  texts: PropTypes.object,
  /** optional callback ref for the outer div of the text control */
  wrapperCallbackRef: PropTypes.func,
  /** Sets the autocomplete behaviour of the input. */
  autoComplete: PropTypes.string
};

TextField.displayName = 'Control.Text';

export default TextField;
