import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

/**
 * Recaptcher make a recaptcha call/execution when the route changes
 */

export class Recaptcher extends React.Component {
  constructor() {
    super();
    this.execute = this.execute.bind(this);
  }

  componentDidMount() {
    this.execute();
  }

  shouldComponentUpdate(nextProps) {
    const before = this.props.location && this.props.location.pathname;
    const after = nextProps.location && nextProps.location.pathname;
    return before !== after;
  }

  componentDidUpdate() {
    this.execute();
  }

  execute() {
    if (this.props.executeAction) {
      this.props.executeAction(this.props.location && this.props.location.pathname);
    }
  }

  render() {
    return null;
  }
}

Recaptcher.propTypes = {
  /* A functions which takes actions name as parameter*/
  execute: PropTypes.func
};

export default withRouter(Recaptcher);
