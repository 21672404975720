import React, {useState, useCallback} from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import NakedRadio from 'shared/ui/atoms/input/radio/naked';
import BodyText from 'shared/ui/atoms/text/body';
import TextSecondary from 'shared/ui/atoms/text/secondary';
import symbols from 'shared/ui/symbols';
import getRandomString from 'shared/ui/helpers/getRandomString';
import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import propsFilter from 'shared/ui/helpers/propsFilter';
import styles from '../../checkbox/labeled/styles.scss';

const Radio = ({children, emphasis, description, onChange, id = getRandomString(), ...props}) => {
  const [checked, setChecked] = useState(props.checked !== undefined ? props.checked : props.defaultChecked);
  const isControlled = props.checked !== undefined && onChange !== undefined;

  const textProps = {
    id: `radio_label_${id}`
  };

  const ariaAttributesFromProps = propsFilter(props).ariaAttributes().getFiltered();

  const composePropsFilter = propsFilter(props)
    .styles()
    .like(/^title$/)
    .dataAttributes();
  const allowedProps = composePropsFilter.getFiltered();
  const restProps = composePropsFilter.excludeFiltered();
  const stylesName = mapPropsToStyleNames([{emphasis, disabled: props.disabled}]);

  const ariaLabelledBy = props['aria-labelledby'] ? `${props['aria-labelledby']} ${textProps.id}` : textProps.id;

  const ariaAttributes =
    ariaAttributesFromProps['aria-label'] || ariaAttributesFromProps['aria-labelledby']
      ? {...ariaAttributesFromProps, 'aria-labelledby': ariaLabelledBy}
      : {...ariaAttributesFromProps, 'aria-labelledby': textProps.id};

  const handleChange = useCallback(
    e => {
      if (!isControlled) {
        setChecked(e.target.checked);
      }

      if (onChange) {
        onChange(e);
      }
    },
    [onChange, isControlled]
  );

  return (
    (<label
        {...allowedProps}
        className={clsx({
          [styles[stylesName]]: !!stylesName,
          [styles["labeled-input"]]: true,
          [styles["with-description"]]: !!description
        }, allowedProps.className)}
        role="presentation"
        data-checked={isControlled ? props.checked : checked}
      >
      <NakedRadio {...restProps} className={clsx(styles.input, restProps.className)} id={id} {...ariaAttributes} onChange={handleChange} />
      {description ? (
        <div {...textProps}>
          <BodyText>{children}</BodyText>
          <TextSecondary className={styles.description}>{description}</TextSecondary>
        </div>
      ) : (
        <BodyText {...textProps}>{children}</BodyText>
      )}
    </label>)
  );
};

Radio.displayName = 'Control.Radio';

Radio[symbols.Input.Radio.Labeled] = true;

Radio.propTypes = {
  description: PropTypes.string,
  emphasis: PropTypes.bool,
  ...NakedRadio.propTypes
};

export default Radio;
