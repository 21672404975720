import React from 'react';
import Loadable from 'react-loadable';
import {matchPath} from 'react-router';
import _Recaptcher from 'shared/components/recaptcher';
import loadScript from 'shared/helpers/loadScript';
import config from 'candidate/config';
import featurePolicy from 'candidate/featuresPolicy';
import {APPLY_ROUTE, JOB_ROUTE, ROOT} from 'candidate/shared/paths';

const RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js?render=';

export const getAction = path => {
  const applyPath = matchPath(path, {path: APPLY_ROUTE});
  const jobPath = matchPath(path, {path: JOB_ROUTE});
  const careersPath = matchPath(path, {path: ROOT});

  if (applyPath && applyPath.params && applyPath.params.account && applyPath.params.job && applyPath.isExact) {
    return 'applicationForm';
  }

  if (jobPath && jobPath.params && jobPath.params.account && jobPath.params.job && jobPath.isExact) {
    return 'job';
  }

  if (careersPath && careersPath.isExact) {
    return 'careersPage';
  }

  return 'unknown';
};

export default Loadable({
  loader: () => {
    const fp = featurePolicy();
    if (Object.keys(fp.features()).includes('recaptcha') && !fp.allows('recaptcha')) {
      return Promise.resolve('disabled');
    }
    let key;
    return config
      .then(({recaptcha}) => {
        key = recaptcha && recaptcha.clientKey;
        return key && loadScript(`${RECAPTCHA_URL}${key}`);
      })
      .then(() => window.grecaptcha && new Promise(resolve => window.grecaptcha.ready(resolve)))
      .then(() => () =>
        key ? (
          <_Recaptcher
            executeAction={path =>
              window.grecaptcha &&
              window.grecaptcha.execute &&
              window.grecaptcha.execute(key, {action: getAction(path)})
            }
          />
        ) : null
      );
  },
  loading: () => null
});
