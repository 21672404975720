export const SIZES = {
  mid: 'mid',
  large: 'large'
};

export const POSITIONS = Object.freeze({
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  HORIZONTAL_CENTER: 'HORIZONTAL_CENTER',
  VERTICAL_CENTER: 'VERTICAL_CENTER'
});

export const MOBILE_MARGIN = 4;
