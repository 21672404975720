import {generatePath, matchPath} from 'react-router';

export const NO_ACCOUNT = '_';

export const ROOT_WITHOUT_SLASH = '/:account';
export const JOB_ROUTE_WITHOUT_SLASH = '/:account/j/:job';
export const APPLY_ROUTE_WITHOUT_SLASH = '/:account/j/:job/apply';
export const EEOC_WITHOUT_SLASH = '/:account/candidates/:id/eeoc_profile/new';
export const GDPR_POLICY_WITHOUT_SLASH = '/:account/gdpr_policy';
export const OOPS_WITHOUT_SLASH = '/oops';
export const SURVEY_WITHOUT_SLASH = '/:account/candidates/:id/surveys/:surveyId';
export const SURVEY_SUBMITTED_WITHOUT_SLASH = '/:account/surveys/submitted';
export const SURVEY_EXPIRED_WITHOUT_SLASH = '/:account/surveys/expired';

export const ROOT = [ROOT_WITHOUT_SLASH, '/'].join('');
export const JOB_ROUTE = [JOB_ROUTE_WITHOUT_SLASH, '/'].join('');
export const APPLY_ROUTE = [APPLY_ROUTE_WITHOUT_SLASH, '/'].join('');
export const GDPR_POLICY = [GDPR_POLICY_WITHOUT_SLASH, '/'].join('');
export const OOPS = [OOPS_WITHOUT_SLASH, '/'].join('');
export const EEOC = [EEOC_WITHOUT_SLASH, '/'].join('');
export const SURVEY = [SURVEY_WITHOUT_SLASH, '/'].join('');
export const SURVEY_SUBMITTED = [SURVEY_SUBMITTED_WITHOUT_SLASH, '/'].join('');
export const SURVEY_EXPIRED = [SURVEY_EXPIRED_WITHOUT_SLASH, '/'].join('');
export const FILTERS = '/:account/filters/';
export const FORM_PREVIEW = '/j/preview/#form_preview';
export const JOB_PREVIEW = '/j/preview';
export const CAREERS_PREVIEW = '#careers_preview';
export const GDPR_PREVIEW = '#gdpr_preview';
export const SURVEY_PREVIEW = '#survey_preview';
export const IFRAME_PREVIEW = '#advanced_careers_preview';
export const GDPR_POLICY_WITH_VERSION = '/:account/gdpr_policy/:version';

export const EDIT_WITHOUT_SLASH = '/:account?/edit';
export const PREVIEW_WITHOUT_SLASH = '/:account?/preview/:id?';
export const SHIELD = '/auth/shield';
export const SHIELD_CALLBACK = '/auth/shield/callback';

export const EDIT = [EDIT_WITHOUT_SLASH, '/'].join('');
export const PREVIEW = [PREVIEW_WITHOUT_SLASH, '/'].join('');

export const account = (p = window && window.location && window.location.pathname) => {
  const match = matchPath(p, {path: ROOT});
  return (match && match.params && match.params.account) || NO_ACCOUNT;
};

export const draftId = (p = window && window.location && window.location.pathname) => {
  const match = matchPath(p, {path: PREVIEW});
  return (match && match.params && match.params.id) || null;
};

export default {
  advanced: {
    preview: id => generatePath(PREVIEW, {account: account(), id})
  },
  apply: job => generatePath(APPLY_ROUTE, {account: account(), job}),
  filters: () => generatePath(FILTERS, {account: account()}),
  gdpr: () => generatePath(GDPR_POLICY, {account: account()}),
  j: (job, {hasCustomDomain} = {}) => generatePath(`${hasCustomDomain ? '_/' : ''}j/:job/`, {job}),
  job: job => generatePath(JOB_ROUTE, {account: account(), job}),
  root: () => generatePath(ROOT, {account: account()}),
  surveySubmitted: () => generatePath(SURVEY_SUBMITTED, {account: account()})
};
