import withInMemorySearch from './withInMemorySearch';

let cps;

if (__ENV__ === 'e2e') {
  cps = withInMemorySearch(require('./fake').default);
} else {
  cps = require('./cps').default;
}

export default cps;
