import React from 'react';
import PropTypes from 'prop-types';
import BaseSvg, {setSymbol} from '../base';
import Graph from '../base/graph';
import '../base/graph.scss';

const IconSprite = ({
  svg,
  size,
  width,
  height,
  responsive,
  fallback,
  title,
  className,
  transparent,
  color,
  transform,
  background,
  preserveAspectRatio,
  ...props
}) => {
  if (!svg || !svg.id) {
    throw new Error(
      [
        'A SVG is required to imported in your app and',
        'loaded with svg-sprite-loader(commonLoaders.withSvg())',
        'then passed as `svg` prop in this component'
      ].join(' ')
    );
  }

  return (
    <BaseSvg
      {...{
        size,
        width,
        height,
        color,
        responsive,
        fallback,
        title,
        className,
        transform,
        background,
        preserveAspectRatio
      }}
      className={className}
      viewBox={`${svg.viewBox}`}
      preserveAspectRatio={preserveAspectRatio}
    >
      <Graph {...props} transparent={transparent} as="use" xlinkHref={`#${svg.id}`} />
    </BaseSvg>
  );
};

IconSprite.displayName = 'Icon.Sprite';

IconSprite.propTypes = {
  /**
   * The svg loaded with svg-sprite-loader. Use `webpackloaders.withSvg()` into your
   * webpack config then normally import svg files or use it without webpack changes
   * `import svg from 'path/to/svg?sprite';
   * */
  svg: PropTypes.shape({
    id: PropTypes.string,
    viewBox: PropTypes.string,
    content: PropTypes.string
  }),
  ...Graph.propTypes,
  ...BaseSvg.propTypes
};

setSymbol(IconSprite);

export default IconSprite;
