import {useContext, useEffect, useState} from 'react';
import api from 'candidate/api';
import Shortcode from 'candidate/providers/shortcode';

const noop = _ => _;

const Attribution = ({callback = noop}) => {
  const shortcode = useContext(Shortcode);

  // Don't attribute twice for a job
  // https://workable.atlassian.net/browse/CAN-1087
  const [shouldAttribute, setShouldAttribute] = useState(!shortcode);

  useEffect(() => {
    setShouldAttribute(shouldAttribute || !shortcode);
  }, [shortcode]);

  useEffect(() => {
    if (shouldAttribute && shortcode && callback) {
      callback(api.attribute(shortcode));
    }
  }, [shouldAttribute, shortcode, callback]);
  return null;
};

export default Attribution;
