const Redirect = ({_window = window, to}) => {
  if (!to) {
    throw new Error('to prop is missing ');
  }

  _window.location = to;
  return null;
};

export default Redirect;
