import React from 'react';
import {withRouter} from 'react-router-dom';
import Header from '../../header';
import Eeoc from '../eeoc';
import styles from './styles.scss';

export const _EeocPage = ({match}) => (
  <>
    <Header withoutJob />
    <div className={styles.page}>
      <Eeoc id={match && match.params && match.params.id} />
    </div>
  </>
);

export default withRouter(_EeocPage);
