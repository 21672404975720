import {useEffect} from 'react';
import analytics from 'candidate/analytics';
import featurePolicy from 'candidate/featuresPolicy';
import hotjar from 'candidate/shared/hotjar';

const useRegisterVariablesWithEffect = (variables = {}) => {
  useEffect(() => {
    analytics
      .then(a => {
        Promise.all(
          Object.keys(variables).map(name => {
            const value = variables[name];
            return typeof value !== 'undefined' ? a.variable(name, value).send() : Promise.resolve();
          })
        );
      })
      .then(() => {
        const tags = Object.keys(variables).filter(name => !!variables[name]) || [];
        hotjar.tag(...tags);
      });
  }, Object.values(variables));
};

const Variables = () => {
  const analyticsDimensionsGetter = () => window?.careers?.dimensions || {};
  const dimensionsPolicy = featurePolicy(analyticsDimensionsGetter);

  useRegisterVariablesWithEffect({i18n: dimensionsPolicy.allows('i18n')});
  useRegisterVariablesWithEffect({advanced: dimensionsPolicy.allows('advanced')});
  useRegisterVariablesWithEffect({customDomain: dimensionsPolicy.allows('hasCustomDomain')});
  useRegisterVariablesWithEffect({old_cookie_consent_design: false});

  return null;
};

export default Variables;
