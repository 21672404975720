import '@workable/shield-spa-sdk';
import 'core-js/stable';
import 'react-hot-loader';
import React from 'react';
import ReactDom from 'react-dom';
import SharedTheme from 'shared/ui/atoms/theme';
import {contrastAccessibleColors} from 'shared/ui/atoms/theme/themes';
import config from 'candidate/config';
import * as i18n from 'candidate/i18n';
import CandidateTheme from 'candidate/shared/theme';
import App from './app';

export const initialize = async (hostname = window.location.hostname) => {
  const {ignoredHostnames = []} = await config;
  if (ignoredHostnames && ignoredHostnames.includes(hostname)) {
    return;
  }

  // Always load i18n first, in order for the language detection logic
  // to trigger before we render anything else.
  await i18n.init();

  ReactDom.render(
    <>
      <SharedTheme theme={contrastAccessibleColors} />
      <CandidateTheme />
      <App />
    </>,
    document.getElementById('app')
  );
};

initialize();
