import React from 'react';
import Loadable from 'react-loadable';
import config from 'candidate/config';
import Theme from 'candidate/shared/theme';
import styles from './styles.scss';

const defaultValue = window.document.documentElement.style.getPropertyValue('--color-default') || '#00756a';

export const Debug = ({commithash = __COMMIT_HASH__, version = __VERSION__}) => {
  const [theme, setTheme] = React.useState(window.document.documentElement.style.getPropertyValue('--color-default'));
  const onThemeChange = React.useCallback(event => setTheme(event.target.value), [setTheme]);

  return (
    <>
      <Theme theme={theme} />
      <div className={styles.debug}>
        <div>
          tag: {version} commit: {commithash}
        </div>
        <label htmlFor="theme">
          theme: <input defaultValue={defaultValue} id="theme" name="theme" onChange={onThemeChange} type="color" />
        </label>
      </div>
    </>
  );
};

export const DebugWithConfig = c =>
  Loadable({
    loader: () => c.then(({debug}) => () => (debug ? <Debug /> : null)),
    loading: () => null
  });

export default DebugWithConfig(config);
